<template>
  <section id="main-home">
    <!-- #d59e0e30,#0a1a726b -->
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/main-home1.jpg')"
      height="900"
      class="white--text"
    >
      <v-container class="fill-height">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          :max-width="maxWidth"
          width="100%"
        >
          <base-subheading
            class="pt-0 pb-4"
            color="secondary--text"
            size="text-h5"
            weight="regular"
            title="As a leading OEM glove business, we take pride in our top-quality gloves and commitment to excellence in being your reliable partner in hand protection solutions locally and internationally."
            align="center"
            mobileSize="text-h6"
          />
          <base-heading
            class="pb-16 mb-16"
            size="text-h3"
            title="Are you looking for a reliable supply of high quality gloves?"
            weight="bold"
            color="black--text"
            align="center"
            mobileSize="text-h5"
          />
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
        </v-responsive>
      </v-container>
    </v-img>
    <v-row
      class="py-16 px-16"
    >
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <base-info-card-pacific
          v-bind="cards[0]"
          align="center"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        sm="3"
        md="3"
      >
        <v-img
          contain
          min-height="150"
          :src="require('@/assets/howellcare_main4.png')"
        >
        </v-img>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <base-info-card-pacific
          v-bind="cards[1]"
          align="center"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainHome',
    data () {
      return {
        cards: [
          {
            title: 'Large/Established Distributor?',
            subtitle: '<ul><li>Are you a large distributor?</li><li>Are you seeking a reliable and efficient supply chain?</li><li>Do you have your own established glove brand?</li><li>I import gloves across the region</li></ul>',
            text: '',
            callout: '',
            icon: 'mdi-earth',
            link: 'products',
          },
          {
            title: 'New/Small Business Owner?',
            subtitle: '<ul><li>Are you in search of specialized hand protection solutions?</li><li>Do you want to kickstart your own glove business?</li><li>I am a new importer</li><li>Are you an end-user of disposable gloves?</li></ul>',
            text: '',
            callout: '',
            icon: 'mdi-office-building-outline',
            link: '#ansoncare',
          },
        ],
      }
    },
    provide: {
      theme: { isDark: true },
    },

    computed: {
      maxWidth () {
        const maxWidth = this.$vuetify.breakpoint.mdAndUp ? '1400px' : '700px'

        return maxWidth
      },
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      fnGoToAbout () {
        this.$router.push('/about')
      },
    },
  }
</script>
